<template>
  <div id="news">
    <div class="ad-container">
      <Adsense
        data-ad-client="ca-pub-6699354447815429" 
        data-ad-slot="8230887469"
        data-ad-format="horizontal"
        data-full-width-responsive="true">
      </Adsense>
    </div>
    <h1>Enter Keyword</h1>
    <input class="news-component" type="text" id="stock_symbol" placeholder="Enter Keyword" v-on:keyup.enter="searchStockNews()">
    <button class="news-component" @click="searchStockNews()">Search</button>
    <div id="stock_news"></div>
  </div>

</template>

<script>
// import { useMeta } from 'vue-meta'
import { inject } from 'vue'
export default {
  setup() {
    const setMeta = inject('setMeta')
    setMeta({
      title: 'SoSoCEO - 사업하는 개발자',
      keywords: '뉴스,news,예제,기사검색,뉴스검색,html,웹,앱,개발,코딩,chatgpt,AI,크롤링,craling',
      description: '인터넷 뉴스 기사를 크롤링해서 조회하는 HTML 예제입니다.',
      'og:title': 'SoSoCEO - 사업하는 개발자',
      'og:description': '인터넷 뉴스 기사를 크롤링해서 조회하는 HTML 예제입니다.',
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': 'https://geek9.kr/news',
      'canonical': 'https://geek9.kr/news'
    })
    // useMeta( {
    //   title: 'SoSoCEO - 사업하는 개발자',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/news'}],
    //   meta: [
    //     { vmid: 'charset_tnews', charset: 'utf-8' },
    //     { vmid: 'http-equiv_tnews', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_tnews', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_tnews', name: 'keywords', content:'뉴스,news,예제,기사검색,뉴스검색,html,웹,앱,개발,코딩,chatgpt,AI,크롤링,craling' },
    //     { vmid: 'viewport_tnews', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_tnews', name: 'description', content: '인터넷 뉴스 기사를 크롤링해서 조회하는 HTML 예제입니다.' },
    //     { vmid: 'theme-color_tnews', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_tnews', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_tnews', property: 'og:description', content: '인터넷 뉴스 기사를 크롤링해서 조회하는 HTML 예제입니다.'},
    //     { vmid: 'og:title_tnews', property: 'og:title', content: 'SoSoCEO - 사업하는 개발자'},
    //     { vmid: 'og:image_tnews', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_tnews', property: 'og:url', content: 'https://geek9.kr/news'}
    //   ]
    // })
    return {
    }
  },
  components: {
  },
  mounted() {
    this.api.setPage('tnews')
    console.log(this.api.getCookies('page'))
    this.$emit("setMenu", {page:'tnews'})
  },
  data(){
    return{
    }
  },
  methods : {
    async searchStockNews() {
      const type = 'news'
      const keyword = document.getElementById('stock_symbol').value
      await this.api.axiosGql(type, {type:type, keyword:keyword})
      .then( async (res) => {
        if(res === undefined) alert('다시 시도해주세요.')  
        else this.displayNews(res.data)
      }).catch( (err) => {
        alert('다시 시도해주세요.')
        console.log(err)
      })
    },
    displayNews(articles) {
      const stockNewsDiv = document.getElementById('stock_news')
      stockNewsDiv.innerHTML = ''
      articles.forEach(article => {
        const articleLink = document.createElement('a')
        articleLink.href = article.url
        articleLink.textContent = article.title
        const articleListItem = document.createElement('li')
        articleListItem.appendChild(articleLink)
        stockNewsDiv.appendChild(articleListItem)
      })
    }
  }
}
</script>

<style>
.news-component {
  padding:5px;
  margin-right:5px;
  width:fit-content
}
</style>
